@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-ThinItalic.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-ThinItalic.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-ThinItalic.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: "italic";
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-ExtraLightItalic.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-ExtraLightItalic.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-ExtraLightItalic.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: "italic";
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-LightItalic.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-LightItalic.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-LightItalic.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: "italic";
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-RegularItalic.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-RegularItalic.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-RegularItalic.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-RegularItalic.ttf") format("truetype");
    font-weight: 400;
    font-style: "italic";
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-MediumItalic.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-MediumItalic.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-MediumItalic.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: "italic";
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-SemiBoldItalic.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-SemiBoldItalic.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-SemiBoldItalic.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: "italic";
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-BoldItalic.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-BoldItalic.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-BoldItalic.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: "italic";
}


@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-ExtraBoldItalic.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-ExtraBoldItalic.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-ExtraBoldItalic.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: "italic";
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-BlackItalic.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-BlackItalic.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-BlackItalic.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: "italic";
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-Thin.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-Thin.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-Thin.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-Thin.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-ExtraLight.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-ExtraLight.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-ExtraLight.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-ExtraLight.ttf") format("truetype");
    font-weight: 200;
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-Light.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-Light.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-Light.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-Regular.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-Regular.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-Regular.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-Medium.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-Medium.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-Medium.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-SemiBold.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-SemiBold.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-SemiBold.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-Bold.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-Bold.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-Bold.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-Bold.ttf") format("truetype");
    font-weight: 700;
}


@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-ExtraBold.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-ExtraBold.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-ExtraBold.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}

@font-face {
  font-family: "Metropolis";
  font-display: swap;
  src: local("Metropolis"),
    url("./fonts/Metropolis/Webfonts/EOT/Metropolis-Black.eot") format("eot"),
    url("./fonts/Metropolis/Webfonts/WOFF2/Metropolis-Black.woff2") format("woff2"),
    url("./fonts/Metropolis/Webfonts/WOFF/Metropolis-Black.woff") format("woff"),
    url("./fonts/Metropolis/TrueType/Metropolis-Black.ttf") format("truetype");
    font-weight: 900;
}


body {
  margin: 0;
  font-family: "Metropolis";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Scroll Bar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3c373d;
}

input {
  user-select: text;
  -webkit-user-select: text;
}